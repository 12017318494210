var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[(!_vm.repair)?_c('span',{staticClass:"resize-loading"},[_c('span',{staticClass:"loader"})]):_c('div',{staticClass:"elite-tabs-wrapper"},[_c('tabs',{staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper tabs-wrapper-nav","tabNavClasses":"nav elite-tabs","value":_vm.getActiveTab()},on:{"onTabChanged":_vm.tabChanged}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-list"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.GLOBAL"))+" ")]),_c('div',{staticClass:"tab-pane-inner"}),_c('repair-view-global',{attrs:{"repair":_vm.repair},on:{"onCancelRepair":_vm.onCancelRepair,"onEditRepair":_vm.onEditRepair,"onViewRepair":_vm.onViewRepair}})],1),_c('tab-pane',{attrs:{"title":"images","id":"2","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-image"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.PICTURES"))+" ")]),_c('repair-view-images',{attrs:{"repair":_vm.repair}})],1),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_INSPECTIONS) &&
          !!_vm.repair.inspection
        )?_c('tab-pane',{attrs:{"title":"inspection","id":"3","active":false}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-clipboard-check"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.INSPECTION"))+" ")]),_c('div',{staticClass:"tab-pane-inner"}),_c('repair-view-inspection',{attrs:{"repair":_vm.repair},on:{"onViewRepair":_vm.onViewRepair}})],1):_vm._e(),(
          !!_vm.repair.estimation &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTIMATIONS)
        )?_c('tab-pane',{attrs:{"title":"estimation","id":"4","active":true}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"far fa-usd-circle"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.ESTIMATION"))+" ")]),_c('div',{staticClass:"tab-pane-inner"},[_c('repair-view-estimate',{attrs:{"loading":_vm.loading,"repair":_vm.repair},on:{"onViewRepair":_vm.onViewRepair}})],1)]):_vm._e(),(_vm.repair.status != _vm.REPAIR_STATUS_DRAFT)?_c('tab-pane',{attrs:{"active":false,"id":"5","title":"repair"}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa-regular fa-wrench"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.REPAIR"))+" ")]),_c('div',{staticClass:"tab-pane-inner"},[_c('repair-view-repair',{key:_vm.repairViewRepairKey,attrs:{"repairData":_vm.repair,"loading":_vm.isLoading,"hourlyRateValue":_vm.hourlyRate},on:{"repairUpdated":_vm.repairUpdated,"onCloseRepairModal":_vm.onCloseRepairModal,"onCancelRepair":_vm.onCancelRepair,"onStartRepair":_vm.onStartRepair,"onEndRepair":_vm.onEndRepair,"onUpdateRepair":_vm.updateRepair,"onTakeOverRepair":_vm.onTakeOverRepair}})],1)]):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_VEHICLES) &&
          !!_vm.repair.vehicle
        )?_c('tab-pane',{attrs:{"title":"vehicle","id":"6","active":false}},[_c('span',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"fa fa-car"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.VEHICLE"))+" ")]),_c('div',{staticClass:"tab-pane-inner"},[_c('repair-view-vehicle',{attrs:{"repair":_vm.repair},on:{"onUpdateRepair":_vm.updateRepair,"onViewRepair":_vm.onViewRepair}})],1)]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }