<template>
  <div>
    <div class="container-fluid">
      <vehicle-view-global
        :vehicle="repair.vehicle"
        @onViewVehicle="$emit('onViewRepair')"
      >
        <template #header> <span></span> </template>
      </vehicle-view-global>
    </div>
  </div>
</template>

<script>
import alertLeave from "@/mixins/alert-leave-mixin";
import VehicleViewGlobal from "@/views/Pages/VehiclesModule/VehicleManagement/partials/VehicleViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    VehicleViewGlobal,
  },

  mixins: [alertLeave],

  props: ["repair"],

  data() {
    return {};
  },

  created() {},

  computed: {},

  methods: {},

  watch: {},
};
</script>
